import { createContainer, useContainer } from "unstated-next";
import type { Theme } from "@ovrsea/vulcan";
import { useLocalState } from "../../hooks/useLocalState";

const useDarkModeContainer = () => {
  const [isDarkModeEnabled, setDarkMode] = useLocalState(
    false,
    "darkModeEnabled",
  );

  const toggleDarkMode = () => {
    setDarkMode(!isDarkModeEnabled);
  };

  return { isDarkModeEnabled, toggleDarkMode };
};

const DarkModeProviderContainer = createContainer(useDarkModeContainer);

export const useDarkMode = () => useContainer(DarkModeProviderContainer);
export const DarkModeProvider = DarkModeProviderContainer.Provider;

export const darkModeColors = {
  background: {
    atlas: "#101720",
    // saved
    atlasHeader: "#232E3D",
    // white
    feed: "#101720",

    // saved
    light: "#101720",

    ["nav-active"]: "var(--colors-primary-light)", // #FDFDFD
  },

  brand: {
    // Elf
    base: "#00898F",
    // Shamrock
    dark: "#00565B",
    light: "#49D6CB", // Emerald green
  },

  neutral: {
    10: "#374559",
    20: "#4D5D73",
    30: "#65758B",

    40: "#7F8FA4",
    50: "#9AA8BB",
    60: "#B7C2D1",
    70: "#D5DCE6",
    base: "#F5F7F9",
    dark: "#232E3D",
    light: "#101720",
  },

  primary: {
    base: "#00898F",
    dark: "#00565B",
    light: "#49D6CB",
  },
} satisfies Partial<Theme["colors"]>;

export const pinkModeColors = {
  brand: {
    base: "#E50070",
    dark: "#B30057",
    light: "#FAD0E5",
  },

  primary: {
    base: "#E50070",
    dark: "#B30057",
    light: "#FAD0E5",
  },
} satisfies Partial<Theme["colors"]>;
