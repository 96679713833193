import { mapValues } from "lodash-es";
import type { MessageDescriptor } from "react-intl";
import { defineMessage } from "react-intl";

type ProcessedMessage<T> = {
  [U in keyof T]: T[U] extends string
    ? MessageDescriptor
    : ProcessedMessage<T[U]>;
};

const processMessage =
  <T extends object, U extends string>(path?: string) =>
  (unprocessedMessage: T, key: U) => {
    const id = path ? `${path}.${key}` : key;

    if (typeof unprocessedMessage === "string") {
      return defineMessage({ defaultMessage: unprocessedMessage, id });
    }

    // eslint-disable-next-line no-use-before-define
    return getMessages(unprocessedMessage, id);
  };

const getMessages = <T extends object>(
  unprocessedMessages: T,
  path?: string,
) => {
  // @ts-expect-error
  const processedMessages: ProcessedMessage<T> = mapValues(
    unprocessedMessages,
    processMessage(path),
  );

  return processedMessages;
};

export default getMessages;
