import { gql } from "@apollo/client/core";

export const showSurveyForUserMutation = gql`
  mutation showSurveyForUser($id: ID!) {
    showSurveyForUser(id: $id) {
      id
      client {
        id
        name
      }
      email
      isDecisionMaker
      language
      name
      role
    }
  }
`;

export const acceptInvitationMutation = gql`
  mutation acceptInvitation($token: String!, $input: AcceptInvitationInput!) {
    acceptInvitation(token: $token, input: $input) {
      ... on User {
        id
      }
      ... on LogisticAgentUser {
        id
      }
    }
  }
`;

export const createClientInvitationMutation = gql`
  mutation createClientInvitation($input: CreateClientInvitationInput!) {
    createClientInvitation(input: $input) {
      id
      name
    }
  }
`;

export const updateUserFromAtlasMutation = gql`
  mutation updateUserFromAtlas($input: UpdateUserFromAtlasInput!) {
    updateUserFromAtlas(input: $input) {
      id
      createdAt
      updatedAt
      email
      name
      phone
      pictureUrl
      role
    }
  }
`;
