import type { ReactNode } from "react";
import React from "react";

import ErrorPage from "../../pages/ErrorPage";
import { logError } from "../../libs/errors/logError";

const MainErrorBoundary = class MEB extends React.Component<
  { children?: ReactNode | ReactNode[] },
  { error?: Error; hasError: boolean }
> {
  state = { error: undefined, hasError: false };

  componentDidCatch(error: Error): void {
    this.setState({ error, hasError: true });
    logError(error, null, "top_level_error");
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} mainBoundary />;
    }

    return this.props.children;
  }
};

export { MainErrorBoundary };
