import { useState } from "react";
import { useCookies } from "react-cookie";
import { useApolloClient } from "@apollo/client";
import { useAuthenticationContainer } from "../components/Auth/AuthenticationContext";

const COOKIE_NAME = !__HERMES__ ? "atoken" : "htoken";

const options = {
  path: "/",
  secure: process.env.NODE_ENV === "production",
};

export const useAuthTokenStorage = () => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

  const removeToken = () => removeCookie(COOKIE_NAME, options);
  const setToken = (token: string) => setCookie(COOKIE_NAME, token, options);

  return [cookies[COOKIE_NAME], setToken, removeToken] as const;
};

export const useAtlasLogout = () => {
  const { logout: authenticationLogout } = useAuthenticationContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const apolloClient = useApolloClient();

  const logout = async () => {
    setLoading(true);
    await apolloClient.clearStore();
    authenticationLogout();
    setLoading(false);
  };

  return [logout, loading] as const;
};

export const useHermesLogout = () => {
  const { logout: authenticationLogout } = useAuthenticationContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const apolloClient = useApolloClient();

  const logout = async () => {
    setLoading(true);
    await apolloClient.clearStore();
    authenticationLogout(); // pas de returnto car override React router API
    setLoading(false);
  };

  return [logout, loading] as const;
};
