import type { PropsWithChildren } from "react";
import React from "react";
import type { Theme, ThemeProviderDependencies } from "@ovrsea/vulcan";
import { ThemeProvider } from "@ovrsea/vulcan";
import { useIntl } from "@ovrsea/i18n";
import { messages } from "@ovrsea/i18n";
import { logError } from "../../libs/errors/logError";
import {
  darkModeColors,
  pinkModeColors,
  useDarkMode,
} from "./DarkThemeProvider";
import { useFeatureFlag } from "./FeatureFlagProvider";

const useDependencies = (): ThemeProviderDependencies => {
  const { formatMessage } = useIntl();

  return {
    logger: (error) => logError(error, null, undefined),
    messages: {
      button: {
        cancel: formatMessage(messages.miscellaneous.button.cancel),
        close: formatMessage(messages.miscellaneous.button.close),
        copy: formatMessage(messages.miscellaneous.button.copy),
        delete: formatMessage(messages.miscellaneous.button.delete),
        download: formatMessage(messages.miscellaneous.button.download),
        hasCopied: formatMessage(messages.miscellaneous.button.hasCopied),
        submit: formatMessage(messages.miscellaneous.button.valid),
      },
      calendar: {
        nextMonth: formatMessage(messages.miscellaneous.button.close),
        previousMonth: formatMessage(messages.miscellaneous.button.close),
      },
      ecoFreighScoreLabel: {
        A: "Best-of-class",
        B: "Low",
        C: "Moderate",
        D: "Still too high",
        E: "Too high",
      },
      select: {
        create: "Create",
        empty: "No options",
        nothingFound: "Nothing found",
      },
    },
  };
};

const determineColors = ({
  colors,
  darkMode,
  pinkMode,
}: {
  colors: Partial<Theme["colors"]> | undefined;
  darkMode: boolean;
  pinkMode: boolean;
}) => {
  if (pinkMode) {
    return pinkModeColors;
  }
  if (darkMode) {
    return darkModeColors;
  }

  return colors;
};

export const FrontThemeProvider = ({
  children,
  colors,
}: PropsWithChildren<{ colors?: Partial<Theme["colors"]> }>) => {
  const { isPinkAtlas } = useFeatureFlag();
  const { isDarkModeEnabled } = useDarkMode();
  const usedOverrideColors = determineColors({
    colors,
    darkMode: isDarkModeEnabled,
    pinkMode: isPinkAtlas,
  });

  return (
    <ThemeProvider
      dependencies={useDependencies()}
      overrideColors={usedOverrideColors}
    >
      {children}
    </ThemeProvider>
  );
};
