import { useSession } from "@ovrsea/ovrlib/components/App/SessionProvider";
import { isEmployee } from "@ovrsea/ovrlib/utils/typescript/isUserOrEmployee";

export const useEmployee = () => {
  const { session } = useSession();
  const employee = session?.person;

  if (!employee) {
    return employee;
  }

  if (!isEmployee(employee)) {
    throw new Error("We must have someone from Ovrsea in this context");
  }

  return employee;
};
