import React from "react";

import { Body, Button, Card, Center, VStack } from "@ovrsea/vulcan";
import { useIntl } from "@ovrsea/i18n";
import { messages } from "@ovrsea/i18n";
import { useAuthenticationContainer } from "../components/Auth/AuthenticationContext";

const InvalidLoginPage = () => {
  const { logout } = useAuthenticationContainer();

  const { formatMessage } = useIntl();

  return (
    <Center sx={{ marginTop: "200px" }}>
      <Card data-testid="InvalidLoginPage" sx={{ padding: "50px" }}>
        <VStack>
          <Body sx={{ marginBottom: "40px", width: "400px" }}>
            <b>{formatMessage(messages.renderErrorMessage.invalidEmail)}</b>{" "}
            {formatMessage(messages.renderErrorMessage.invalidEmailExplanation)}
          </Body>
          <Center>
            <Button label="Logout" onClick={logout} />
          </Center>
        </VStack>
      </Card>
    </Center>
  );
};

export default InvalidLoginPage;
