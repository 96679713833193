export const INVALID_ALLOCATION_PERIOD_CODE = "invalid_allocation_period";

export const CANT_UPLOAD_BILLING_WITH_NO_BILLING_NUMBER_CODE =
  "cant_upload_billing_with_no_billing_number_code";

export const CANNOT_UPLOAD_BILLING_WITH_NO_BILLING_NUMBER_CODE =
  "cannot_upload_billing_with_no_billing_number_code";

export const BILLING_NUMBER_TAKEN_CODE = "billing_number_taken_code";

export const INVOICE_NUMBER_AND_OFFICE_MISMATCH_CODE =
  "invoice_number_and_office_mismatch_code";

export const AUTH0_USER_NOT_REGISTERED_CODE = "AUTH0_USER_NOT_REGISTERED";
export const MISSING_AWS_S3_URL_CODE = "missing_aws_s3_url";

export const CANT_CREATE_INVOICE_FOR_MASTER_OFFICE_CODE =
  "cant_create_invoice_for_master_office_error_code";

export const EMPTY_DOCUMENT_CODE = "empty_document_uploaded";

export const BILLING_MUST_HAVE_A_SHIPOWNER_OR_A_CLIENT_CODE =
  "billing_must_have_a_shipowner_or_a_client_code";

export const BILLING_MUST_HAVE_PRICES_CODE = "billing_must_have_prices";

export const INCORRECT_CONVERSION_EUR_USD_CODE = "incorrect_conversion_eur_usd";

export const EMITTING_AND_RECIPIENT_OFFICE_MUST_BE_DIFFERENT_CODE =
  "emitting_and_recipient_office_must_be_different_code";

export const LOCATIONS_MUST_HAVE_A_CLIENT_CODE =
  "location_must_have_a_client_code";

export const CANNOT_UPDATE_COMPUTED_ROUTING_CODE =
  "computed_routing_is_read_only";

export const FLIGHT_NUMBER_DEPARTURE_DATES_MISMATCH_CODE =
  "flight_number_departure_dates_mismatch";

export const AWB_ERROR_CODE = "awb_error_code";

export const GATEHOUSE_ERROR_CODE = "gatehouse_error_code";
export const GATEHOUSE_REMOTE_ISSUE_CODE = "gatehouse_remote_issue_code";

export const INVOICE_PDF_GENERATION_FAILED_CODE =
  "invoice_pdf_generation_failed_code";

export const CANNOT_ARCHIVE_LOCATION_CODE = "cannot_archive_location_code";

export const INVALID_PROPOSITION_STATUS_TRANSITION_CODE =
  "invalid_proposition_status_transition";

export const PROPOSITION_NOT_EDITABLE_WHEN_IN_STATUS_CODE =
  "proposition_not_editable_when_in_status";

export const CAN_NOT_UPDATE_NON_EXISTING_PRICE_CODE =
  "can_not_update_non_existing_price";
