import React, { useCallback, useState } from "react";
import { createContainer, useContainer } from "unstated-next";

import { useQueryStringTo } from "../useQueryStringModal";
import type { FilePreview } from "../../components/Documents/Modals/ViewerModalContainer";
import {
  LoadableDropboxViewerModal,
  LoadableViewerModal,
} from "../../components/Documents/Modals/ViewerModalContainer.loadable";
import withErrorBoundary from "../../components/Layout/HOCs/withErrorBoundary";
import { BasicAuthenticationGate } from "../../components/Auth/AuthenticationGate";

type Props = {
  documents: FilePreview[];
  uuid: string;
};

const useViewerModalContext = () => {
  const [list, setList] = useState<FilePreview[]>([]);

  const [viewerModalParams, goTo, urlTo] = useQueryStringTo<{
    action: "preview";
    uuid: string | undefined;
  }>();

  const openViewerModal = useCallback(
    ({ documents, uuid }: Props) => {
      setList(documents);
      goTo({ action: "preview", uuid });
    },
    [goTo],
  );
  const closeViewerModal = useCallback(() => goTo({}), [goTo]);
  const viewerModalUrl = useCallback(
    (documentId: string) =>
      urlTo({
        action: "preview",
        uuid: documentId,
      }),
    [urlTo],
  );

  const viewerModalOpened =
    (viewerModalParams &&
      viewerModalParams.action === "preview" &&
      !!viewerModalParams.uuid) ||
    false;

  const previewedDocumentUuid =
    viewerModalParams && viewerModalParams.action === "preview"
      ? viewerModalParams.uuid
      : undefined;

  return {
    closeViewerModal,
    list,
    openViewerModal,
    uuid: previewedDocumentUuid,
    viewerModalOpened,
    viewerModalUrl,
  };
};

const ViewerModalCtx = createContainer(useViewerModalContext);

export const useViewerModalContainer = () => useContainer(ViewerModalCtx);

const useDropboxViewerModalContext = () => {
  const [viewerModalParams, goTo, urlTo] = useQueryStringTo<{
    action: "dropbox-viewer";
    filename: string;
    path: string;
  }>();

  const openViewerModal = useCallback(
    ({ filename, path }: { filename: string; path: string }) => {
      goTo({ action: "dropbox-viewer", filename, path });
    },
    [goTo],
  );
  const closeViewerModal = useCallback(() => goTo({}), [goTo]);
  const viewerModalUrl = useCallback(
    (path: string, filename: string) =>
      urlTo({
        action: "dropbox-viewer",
        filename,
        path,
      }),
    [urlTo],
  );

  return {
    closeViewerModal,
    filename:
      viewerModalParams?.action === "dropbox-viewer"
        ? viewerModalParams.filename
        : undefined,
    openViewerModal,
    path:
      viewerModalParams?.action === "dropbox-viewer"
        ? viewerModalParams?.path
        : undefined,
    viewerModalOpened: viewerModalParams?.action === "dropbox-viewer",
    viewerModalUrl,
  };
};

const DropboxViewerModalCtx = createContainer(useDropboxViewerModalContext);

export const useDropboxViewerModalContainer = () =>
  useContainer(DropboxViewerModalCtx);

export const ViewerModalProvider = withErrorBoundary(
  ({ children }: { children: React.ReactNode }) => {
    if (window.PRERENDERING?.active) {
      return <>{children}</>;
    }

    return (
      <ViewerModalCtx.Provider>
        <DropboxViewerModalCtx.Provider>
          {children}
          <BasicAuthenticationGate>
            <LoadableViewerModal />
            <LoadableDropboxViewerModal />
          </BasicAuthenticationGate>
        </DropboxViewerModalCtx.Provider>
      </ViewerModalCtx.Provider>
    );
  },
);
