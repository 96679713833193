import React, { Fragment, useEffect } from "react";

import {
  Body,
  Box,
  Button,
  Card,
  Center,
  Heading,
  Link,
  VStack,
} from "@ovrsea/vulcan";

import { Helmet } from "react-helmet";
import FrontConfig from "../utils/config";
import { logError } from "../libs/errors/logError";

const gridStyle: React.CSSProperties = {
  backgroundColor: "white",
  height: "100vh",
  margin: 0,
  overflow: "auto",
  width: "100%",
};

type Props = {
  error?: Error;
  is404NotFound?: boolean;
  mainBoundary?: boolean;
};

const useLog404 = (is404NotFound?: boolean) => {
  useEffect(() => {
    if (is404NotFound) {
      logError(new Error("404 Not Found"), null, "not_found");
    }
  }, []);
};

const ErrorPage = ({ error, is404NotFound, mainBoundary }: Props) => {
  useLog404(is404NotFound);

  if (error) {
    console.error(error);
  }

  const { code, description, title } = is404NotFound
    ? {
        code: "404",
        description: <p>If you think it should, please send us an email</p>,
        title: "Page not found",
      }
    : {
        code: "Error",
        description: (
          <VStack spacing={4}>
            <p>
              We do our best to provide a reliable website. However, sometimes,
              a bug escapes...
            </p>
            <Box>
              <p>
                Please feel free to send an email directly to&nbsp;
                <a href={`mailto:${FrontConfig.supportEmail}`}>
                  {FrontConfig.supportEmail}
                </a>
                ,
              </p>
              <p>we will fix it as soon as possible !</p>
            </Box>
          </VStack>
        ),
        title: "Something is broken...",
      };

  const documentTitle = is404NotFound ? "Page not found." : "Unknown error";

  const Container = is404NotFound || mainBoundary ? Fragment : Card;

  return (
    <div data-testid="ErrorPage">
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Container>
        <Center style={gridStyle}>
          <VStack alignItems="center" spacing={16}>
            <Heading as="h1" size="xs">
              {code} - {title}
            </Heading>
            <Body
              isSecondary
              sx={{ margin: "1rem 0 1.5rem", textAlign: "center" }}
            >
              {description}
            </Body>
            <Link href="/dashboard" isUnstyled>
              <Button
                data-testid="Error page: back to dashboard"
                label="Back to dashboard"
              />
            </Link>
          </VStack>
        </Center>
      </Container>
    </div>
  );
};

export default ErrorPage;
