import React from "react";
import { Helmet } from "react-helmet";

const AtlasAppHelmet = () => {
  return (
    <Helmet>
      {
        // Stonly
      }
      {__ENABLE_TRACKERS__ && (
        <script>
          window.STONLY_WID = "5954f92e-08d1-11ed-9fb8-0ae9fa2a18a2";
        </script>
      )}
      {__ENABLE_TRACKERS__ && (
        <script>
          {
            '!(function (s, t, o, n, l, y, w, g) { s.StonlyWidget || (((w = s.StonlyWidget = function () { w._api ? w._api.apply(w, arguments) : w.queue.push(arguments); }).scriptPath = n), (w.queue = []), ((y = t.createElement(o)).async = !0), (g = new XMLHttpRequest()).open( "GET", n + "version?v=" + Date.now(), !0 ), (g.onreadystatechange = function () { 4 === g.readyState && ((y.src = n + "stonly-widget.js?v=" + (200 === g.status ? g.responseText : Date.now())), (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l)); }), g.send()); })(window, document, "script", "https://stonly.com/js/widget/v2/");'
          }
        </script>
      )}
    </Helmet>
  );
};

export { AtlasAppHelmet };
