import React, { createContext, useContext, useEffect } from "react";
import { useOvrseaNavigate } from "@ovrsea/ovrlib/utils/routes/reactRouter";
import { useSession } from "@ovrsea/ovrlib/components/App/SessionProvider";
import { searchOnAlgolia } from "@ovrsea/ovrlib/hooks/algolia/useAlgoliaQuery";
import { indices } from "@ovrsea/ovrlib/utils/algolia/indices";
import { init as initCommandBar } from "commandbar";
import { useSearchClient } from "@ovrsea/ovrlib/components/Algolia/SearchClientProvider";
import type { CallbackFunction } from "commandbar/build/internal/src/client/CommandBarClientSDK";
import type { ICommandFromClientType } from "commandbar/build/internal/src/middleware/ICommandFromClientType";
import { useEmployee } from "../../hooks/useEmployee";

initCommandBar("1aa85d75");

if (typeof (window as any).global === "undefined") {
  (window as any).global = window;
}

const useCommandBarClient = () => {
  const { session } = useSession();
  const algoliaSearchKey = session?.algoliaSearchKey;

  const searchClient = useSearchClient();

  useEffect(() => {
    if (algoliaSearchKey) {
      window.CommandBar.addContext("clients", [], {
        searchOptions: {
          searchFunction: async (query: string) => {
            return searchOnAlgolia({
              algoliaSearchKey,
              applicationId: "93I6NR6ZAV",
              indices: [
                {
                  index: indices.CLIENTS,
                  params: { hitsPerPage: 4 },
                },
              ],

              searchBarCurrentValue: query,
              searchClient,
            });
          },
        },
      });
    }

    return;
  }, [algoliaSearchKey]);
};

const useCommandBarContainer = () => {
  const commandBar = window.CommandBar;
  const navigate = useOvrseaNavigate();

  //specific to ovrsea
  const employee = useEmployee();

  useCommandBarClient();

  const useRegisterCommandCallback = <T, K>(
    name: string,
    callback: CallbackFunction<T, K>,
  ) => {
    useEffect(() => {
      commandBar.addCallback(name, callback);

      return () => commandBar.removeCallback(name);
    }, [commandBar, callback]);
  };

  const useCommandBarContext = (name: string, context: any) => {
    useEffect(() => {
      commandBar.addContext(name, context);

      return () => {
        commandBar.removeContext(name);
      };
    }, [commandBar, name, context]);
  };

  const useCommandBarCommand = (
    command: {
      icon: string;
      recommend?: string;
    } & ICommandFromClientType,
  ) => {
    useEffect(() => {
      void commandBar.addCommand(command);

      return () => {
        commandBar.removeCommand(command.name);
      };
    }, [commandBar, command]);
  };

  const useCommandBarCallback = (
    command: {
      callback: any;
      icon: string;
    } & Omit<ICommandFromClientType, "template">,
  ) => {
    useRegisterCommandCallback(command.name, command.callback);
    useCommandBarCommand({
      ...command,
      icon: "",
      template: {
        type: "callback",
        value: command.name,
      },
    });
  };

  //specific to Ovrsea
  useEffect(() => {
    if (employee?.email) {
      void commandBar.boot(employee.email);
      commandBar.addRouter(navigate);
    }
  }, [employee?.email]);

  useCommandBarContext("role", employee?.role);

  return {
    useCommandBarCallback, //register both the callback and the command
    useCommandBarCommand,
    useCommandBarContext,
    useRegisterCommandCallback, //should be deprecietad in favor of useCommandBarCallback IMO
    //...commandBar,
  };
};

type CommandBarContext = ReturnType<typeof useCommandBarContainer>;

const CommandBarContext = createContext<CommandBarContext>({
  useCommandBarCallback: () => {},
  useCommandBarCommand: () => {},
  useCommandBarContext: () => {},
  useRegisterCommandCallback: () => {},
});

export const useCommandBar = () => useContext(CommandBarContext);

export const CommandBarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CommandBarContext.Provider value={useCommandBarContainer()}>
      {children}
    </CommandBarContext.Provider>
  );
};
