import { ApolloLink, Observable } from "@apollo/client";

const confirmQuitWhenMutationOngoingLink = new ApolloLink(
  (operation, forward) => {
    const operationType = operation.query.definitions.filter(
      (definition) => definition.kind === "OperationDefinition",
    )[0]?.operation;

    function beforeUnloadEventListener(e: BeforeUnloadEvent) {
      const message =
        "Are you sure you want to close Atlas ? There are some unsaved changes which might be lost";

      e.preventDefault();
      e.returnValue = message;

      return message;
    }
    // Check if the operation is a mutation
    if (operationType === "mutation") {
      window.addEventListener("beforeunload", beforeUnloadEventListener);
    }

    // Execute the operation
    const observable = forward(operation);

    // Check if the mutation is done
    return new Observable((observer) => {
      const subscription = observable.subscribe({
        complete: () => {
          if (operationType === "mutation") {
            window.removeEventListener(
              "beforeunload",
              beforeUnloadEventListener,
            );
          }
          observer.complete();
        },
        error: (error) => {
          if (operationType === "mutation") {
            window.removeEventListener(
              "beforeunload",
              beforeUnloadEventListener,
            );
          }
          // Handle errors
          observer.error(error);
        },
        next: (result) => {
          if (operationType === "mutation") {
            window.removeEventListener(
              "beforeunload",
              beforeUnloadEventListener,
            );
          }
          // Perform any necessary actions when the mutation is done

          observer.next(result);
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  },
);

export default confirmQuitWhenMutationOngoingLink;
