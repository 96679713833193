import { useEffect, useState } from "react";
import type { CheckVersionAtlasQuery } from "../../types/store/customTypes";
import { useOvrseaQuery } from "../utils/withOvrseaQuery";
import { checkVersionAtlasQuery } from "./queries";

export const useForceRefreshAtlas = () => {
  const [showForceRefreshBanner, setShowForceRefreshBanner] = useState(false);
  const { data } = useOvrseaQuery<CheckVersionAtlasQuery>(
    checkVersionAtlasQuery,
    {
      fetchPolicy: "network-only",
      pollInterval: 60 * 1000, // poll every minute
      skip: __MOCKED_GRAPHQL__ || __IS_DEV__,
    },
  );

  useEffect(() => {
    if (data && data.checkVersionAtlas.number > __VERSION_NUMBER__ATLAS__) {
      setShowForceRefreshBanner(true);
    }
    if (
      data &&
      data.checkVersionAtlas.minimumVersionOrForceRefresh >
        __VERSION_NUMBER__ATLAS__
    ) {
      globalThis.location?.reload();
    }
  }, [
    data?.checkVersionAtlas.number,
    data?.checkVersionAtlas.minimumVersionOrForceRefresh,
  ]);

  return {
    showForceRefreshBanner,
  };
};
