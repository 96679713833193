import type { LogisticAgentType } from "../../api.generated";

type User = { __typename: "User"; email: string };

type LogisticAgentUser = {
  __typename: "LogisticAgentUser";
  email: string;
  logisticAgent: { type: LogisticAgentType };
};
type BasicPerson =
  | { __typename: "Employee" }
  | { __typename: "LogisticAgentUser" }
  | { __typename: "User" };
type BasicPersonWithEmail = { email: string } & BasicPerson;
type Employee = { __typename: "Employee"; email: string; role: string };

type Person = Employee | LogisticAgentUser | User;

const isUser = <PersonType extends BasicPerson>(
  person: PersonType,
): person is Extract<PersonType, { __typename: "User" }> =>
  person.__typename === "User";

const isLogisticAgentUser = <PersonType extends BasicPerson>(
  person: PersonType,
): person is Extract<PersonType, { __typename: "LogisticAgentUser" }> =>
  person.__typename === "LogisticAgentUser";

const isFreightForwarderUser = (person: Person): boolean =>
  person.__typename === "LogisticAgentUser" &&
  person.logisticAgent.type === "freight_forwarder";

const isWarehouseManagerUser = (person: Person): boolean =>
  person.__typename === "LogisticAgentUser" &&
  person.logisticAgent.type === "warehouse_manager";

const isEmployeeAndTech = (userOrEmployee: Person): boolean =>
  userOrEmployee.__typename === "Employee" && userOrEmployee.role === "dev";

const isUserFromOvrsea = (userOrEmployee: BasicPersonWithEmail): boolean => {
  return !!(
    (isUser(userOrEmployee) || isLogisticAgentUser(userOrEmployee)) &&
    userOrEmployee.email.endsWith("@ovrsea.com")
  );
};

const isEmployee = <PersonType extends BasicPerson>(
  person: PersonType,
): person is Extract<PersonType, { __typename: "Employee" }> =>
  person.__typename === "Employee";

export {
  isEmployee,
  isEmployeeAndTech,
  isFreightForwarderUser,
  isLogisticAgentUser,
  isUser,
  isUserFromOvrsea,
  isWarehouseManagerUser,
};
