// this prevents navigating back in WebKit browsers, IE/Edge ans FF
const preventBackspaceNavigation = (event: KeyboardEvent) => {
  const isBackspace =
    event.code?.toLowerCase() === "backspace" || event.keyCode === 8;
  const isTargetBody = event.target === document.body;

  if (isBackspace && isTargetBody) {
    event.preventDefault();
  }
};

export { preventBackspaceNavigation };
