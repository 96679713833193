import type { PropsWithChildren } from "react";
import React, { Suspense } from "react";
import Loading from "./Loading";
import { LoadableErrorBoundary } from "./LoadableErrorBoundary";

const componentLoader = <Props extends object>(
  lazyComponent: () => Promise<{ default: React.ComponentType<Props> }>,
  attemptsLeft: number,
) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);

            return;
          }

          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject,
          );
        }, 1500);
      });
  });
};

//preloads the whole bundle when we have an idle callback
// const register = (callback: any) => {
//   if (__ATLAS__ && window.requestIdleCallback) {
//     window.requestIdleCallback(callback, { timeout: 60000 });
//   }
// };

const BasicLoadable = <Props extends object>(opts: {
  loader: () => Promise<{ default: React.ComponentType<Props> }>;
  loading?: React.ReactNode;
}) => {
  const LoadedComponent: React.ComponentType<Props> = React.lazy(() =>
    //@ts-expect-error
    componentLoader(opts.loader, 5),
  );
  const Component = (props: PropsWithChildren<Props>) => {
    return (
      <LoadableErrorBoundary>
        <Suspense
          fallback={
            opts.loading === undefined ? <Loading basic /> : opts.loading
          }
        >
          <LoadedComponent {...props} />
        </Suspense>
      </LoadableErrorBoundary>
    );
  };

  Component.preload = opts.loader;
  // register(opts.loader);

  return Component;
};

export default BasicLoadable;
