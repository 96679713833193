import type { PropsWithChildren } from "react";
import React from "react";

import { ApolloProvider } from "@apollo/client";

import { useAtlasApolloClient } from "../../hooks/apollo/useAtlasApolloClient";

const AtlasApolloProvider = ({ children }: PropsWithChildren<object>) => {
  const apolloClient = useAtlasApolloClient();

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AtlasApolloProvider;
