import type { PropsWithChildren } from "react";
import React from "react";

import type { Theme } from "@ovrsea/vulcan";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { LocationModalProvider } from "../hooks/locations/useLocationModal";
import { ViewerModalProvider } from "../hooks/documents/useViewerModalContext";
import { CancelOrderModalProvider } from "../hooks/order/useCancelOrderModalContext";
import { ToastProvider } from "./Utils/ToastProvider";
import { MainErrorBoundary } from "./Error/MainErrorBoundary";
import { ScrollRestoration } from "./Layout/ScrollRestoration";
import { FrontThemeProvider } from "./App/FrontThemeProvider";
import type { StaticFeatureFlag } from "./App/FeatureFlagProvider";
import {
  defaultStaticFeatureFlag,
  FeatureFlagProvider,
} from "./App/FeatureFlagProvider";
import { DarkModeProvider } from "./App/DarkThemeProvider";
import { LoadableDevConfirmModal } from "./App/DevConfirmProvider/DevConfirmModal.loadable";

type AppProps = {
  colors?: Partial<Theme["colors"]>;
  featureFlags?: StaticFeatureFlag;
};

if (__IS_DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export type AppType = (props: PropsWithChildren<AppProps>) => JSX.Element;

const App = ({
  children,
  colors,
  featureFlags = defaultStaticFeatureFlag,
}: PropsWithChildren<AppProps>) => {
  return (
    <FeatureFlagProvider initialState={featureFlags}>
      <DarkModeProvider>
        <FrontThemeProvider colors={colors}>
          <MainErrorBoundary>
            <ScrollRestoration>
              <ToastProvider>
                <LocationModalProvider>
                  <LoadableDevConfirmModal />

                  <CancelOrderModalProvider>
                    <ViewerModalProvider>{children}</ViewerModalProvider>
                  </CancelOrderModalProvider>
                </LocationModalProvider>
              </ToastProvider>
            </ScrollRestoration>
          </MainErrorBoundary>
        </FrontThemeProvider>
      </DarkModeProvider>
    </FeatureFlagProvider>
  );
};

export default App;
