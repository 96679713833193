import { useEffect, useState } from "react";

export const useLatestQueryResult = <QueryResult>(
  asyncQuery: () => Promise<QueryResult>,
  onError?: (err: Error) => any,
) => {
  const [queryResult, setQueryResult] = useState<QueryResult | null>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line immutable/no-let
    let canceled = false;

    setLoading(true);

    void asyncQuery()
      .then((data) => {
        if (!canceled) {
          setQueryResult(data);
        }
      })
      .catch((err) => onError?.(err))
      .finally(() => {
        setLoading(false);
      });

    return () => {
      canceled = true;
    };
  }, [asyncQuery]);

  return {
    loading: isLoading,
    queryResult: queryResult ?? null,
  };
};
