import type { IntlShape } from "react-intl";
import { useIntl as useReactIntl } from "react-intl";

export type ReactIntlShape = Pick<
  IntlShape,
  "formatDate" | "formatMessage" | "formatNumber" | "locale"
>;

export const useIntl = (): ReactIntlShape => {
  const { formatDate, formatMessage, formatNumber, locale } = useReactIntl();

  return { formatDate, formatMessage, formatNumber, locale };
};
