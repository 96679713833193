import { gql } from "@apollo/client/core";
import { clientNotesEmployeeFragment } from "../employees/fragment";

const clientNotesScalarFragment = gql`
  fragment clientNotesScalarFields on Note {
    id
    createdAt
    updatedAt
    categories
    details
  }
`;

const notesFragment = gql`
  fragment notesFields on Note {
    ...clientNotesScalarFields
    employee {
      ...clientNotesEmployeeFields
    }
  }
  ${clientNotesEmployeeFragment}
  ${clientNotesScalarFragment}
`;

export { notesFragment };
