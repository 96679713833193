import { createContainer, useContainer } from "unstated-next";
import { useAuthenticationContainer } from "../Auth/AuthenticationContext";
import { useCheckSession } from "../../graphql/auth/checkSession";
import { useLocaleState } from "../../hooks/intl/useLocale";

const useSessionContainer = () => {
  const { authToken } = useAuthenticationContainer();
  const setLocale = useLocaleState((state) => state.setLocale);

  const result = useCheckSession(authToken);

  if (
    (result.data?.checkSession.person?.__typename === "User" ||
      result.data?.checkSession?.person.__typename === "LogisticAgentUser") &&
    result.data.checkSession.person.language
  ) {
    setLocale(result.data.checkSession.person.language);
  }

  return {
    error: result.error,
    refetch: result.refetch,
    session: result.data?.checkSession,
  };
};

const SessionProvider = createContainer(useSessionContainer);

export const useSession = () => useContainer(SessionProvider);
export default SessionProvider.Provider;
