import type { PropsWithChildren } from "react";
import React, { useEffect, useState } from "react";
import { IntlProvider as Provider } from "react-intl";
import { flatten } from "flat";
import type { SupportedLocales } from "./constants";
import { DEFAULT_LOCALE } from "./constants";

type Messages = {
  [x in SupportedLocales]: { [x: string]: string };
} & {
  override: {
    [x in SupportedLocales]?: { [x: string]: string };
  };
};

type Props = {
  defaultLocale?: SupportedLocales;
  locale?: SupportedLocales;
  withTMSOverride?: boolean;
};

const useLazyMessages = () => {
  const [messages, setMessages] = useState<Partial<Messages>>({});

  useEffect(() => {
    // eslint-disable-next-line immutable/no-let
    let isMounted = true;

    (async () => {
      await import("./messages/fr-FR").then((fr) => {
        if (isMounted) {
          // eslint-disable-next-line max-nested-callbacks
          setMessages((oldMessages) => ({
            ...oldMessages,
            fr: flatten(fr.default),
          }));
        }
      });
      await import("./messages/en-US").then((en) => {
        if (isMounted) {
          // eslint-disable-next-line max-nested-callbacks
          setMessages((oldMessages) => ({
            ...oldMessages,
            en: flatten(en.default),
          }));
        }
      });
      await import("./messages/es-ES").then((es) => {
        if (isMounted) {
          // eslint-disable-next-line max-nested-callbacks
          setMessages((oldMessages) => ({
            ...oldMessages,
            es: flatten(es.default),
          }));
        }
      });
      await import("./messages/it-IT").then((it) => {
        if (isMounted) {
          // eslint-disable-next-line max-nested-callbacks
          setMessages((oldMessages) => ({
            ...oldMessages,
            it: flatten(it.default),
          }));
        }
      });
      await import("./messages/en-US-tms").then((en) => {
        if (isMounted) {
          // eslint-disable-next-line max-nested-callbacks
          setMessages((oldMessages) => ({
            ...oldMessages,
            override: {
              ...oldMessages.override,
              en: flatten(en.default),
            },
          }));
        }
      });
      await import("./messages/fr-FR-tms").then((fr) => {
        if (isMounted) {
          // eslint-disable-next-line max-nested-callbacks
          setMessages((oldMessages) => ({
            ...oldMessages,
            override: {
              ...oldMessages.override,
              fr: flatten(fr.default),
            },
          }));
        }
      });
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  return messages;
};

export const LazyIntlProvider = ({
  children,
  defaultLocale = DEFAULT_LOCALE,
  locale = DEFAULT_LOCALE,
  withTMSOverride = false,
}: PropsWithChildren<Props>) => {
  const messagesSelector = (
    language: SupportedLocales,
    messages: Partial<Messages>,
  ) => {
    const languageMessages = messages[language];

    if (!withTMSOverride) {
      return languageMessages;
    }

    const overrideMessages = messages.override?.[language] ?? {};

    return {
      ...languageMessages,
      ...overrideMessages,
    };
  };

  const messages = useLazyMessages();

  const messagesInLocale = messagesSelector(locale, messages);

  return (
    <Provider
      defaultLocale={defaultLocale}
      key={locale}
      locale={locale}
      messages={messagesInLocale}
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          return; //do nothing for missing translation in dev
        }
        throw err;
      }}
    >
      {children}
    </Provider>
  );
};
