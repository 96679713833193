import { gql } from "@apollo/client/core";

export const employeeScalarFragment = gql`
  fragment employeeScalarFields on Employee {
    id
    createdAt
    updatedAt
    dashboardOwnerIdsFilter
    dropboxUserId
    email
    firstName
    lastName
    name
    ovrseaOffice {
      id
    }
    phone
    pictureUrl
    role
    salesforceId
    status
  }
`;

export const employeeForOrderOwnerFragment = gql`
  fragment employeeForOrderOwnerFields on Employee {
    id
    email
    name
    ovrseaOffice {
      id
    }
    phone
    pictureUrl
    role
    status
  }
`;

export const employeeForActivityFragment = gql`
  fragment employeeForActivityFields on Employee {
    id
    email
    name
    ovrseaOffice {
      id
    }
    phone
    pictureUrl
    role
    status
  }
`;

export const employeeForCheckSessionFragment = gql`
  fragment employeeForCheckSessionFields on Employee {
    ...employeeScalarFields
  }
  ${employeeScalarFragment}
`;

export const billingDashboardOvrseaMessagesEmployeeFragment = gql`
  fragment billingDashboardOvrseaMessagesEmployeeFields on Employee {
    id
    __typename
    name
    pictureUrl
  }
`;

export const clientNotesEmployeeFragment = gql`
  fragment clientNotesEmployeeFields on Employee {
    id
    name
    phone
    pictureUrl
    role
  }
`;
