import type { ReactNode } from "react";
import React from "react";
import { logError } from "../../libs/errors/logError";
import Loading from "./Loading";

class LoadableErrorBoundary extends React.Component<
  { children: ReactNode | ReactNode[] },
  { error: Error | null }
> {
  state = {
    error: null,
  };

  componentDidCatch(err: Error): void {
    this.setState({ error: err });
    //@ts-expect-error
    if (err.type === "timeout") {
      return;
    }
    // Loading chunk fails error
    logError(err, "loadable", "loadable");
  }

  render() {
    if (this.state.error) {
      return <Loading error={this.state.error} />;
    }

    return this.props.children;
  }
}

export { LoadableErrorBoundary };
