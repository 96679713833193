import { useState } from "react";
import { createContainer, useContainer } from "unstated-next";
import { useAuthTokenStorage } from "../../hooks/useAuth";

//TODO: switch to zustand
const useAuthentication = () => {
  const [cookieAuthToken, storeToken, deleteToken] = useAuthTokenStorage();
  const [authToken, setToken] = useState(cookieAuthToken);
  const setAuthToken = (authToken: string) => {
    setToken(authToken);
    storeToken(authToken);
  };
  const removeAuthToken = () => {
    setToken(undefined);
    deleteToken();
  };

  return [authToken, setAuthToken, removeAuthToken];
};

const AuthTokenProvider = createContainer(useAuthentication);

export const useAuthToken = () => useContainer(AuthTokenProvider);
export default AuthTokenProvider.Provider;
