import * as React from "react";
import { Helmet } from "react-helmet";
import OvrseaLogo from "../components/App/OvrseaLogo";
import { useFeatureFlag } from "../components/App/FeatureFlagProvider";
import { OneChainLogo } from "../components/App/OneChainLogo";

const determineLoadingPageBranding = (branding: "onechain" | "ovrsea") => {
  if (branding === "ovrsea") {
    return {
      LogoComponent: OvrseaLogo,
      title: "OVRSEA - Loading",
    };
  }

  return {
    LogoComponent: OneChainLogo,
    title: "OneChain - Loading",
  };
};

const LoadingPage = () => {
  const { branding } = useFeatureFlag();
  // The Loading Page is used in a lot of places, and it's too painful to change
  const { LogoComponent, title } = determineLoadingPageBranding(branding);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta content="width=device-width" name="viewport" />
        <title>{title}</title>
        <style type="text/css">
          {`
          /* Spinner animation frames */
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          /* layout */
          #main-loader {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            height: 100vh;
            width: 100vw;
            background-color: #F4F8F9;
          }
          .spinner {
            -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
            border-color: hsl(215, 25%, 27%);
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-radius: 999px;
            border-style: solid;
            border-width: 2px;
            display: inline-block;
            height: 2em;
            width: 2em;
          }
        `}
        </style>
        <link href={__SERVERLESS_API_URL__} rel="preconnect" />
      </Helmet>
      <main data-testid="LoadingPage" id="main-loader">
        <LogoComponent style={{ width: 215 }} />
        <div className="spinner" />
      </main>
    </div>
  );
};

export default LoadingPage;
