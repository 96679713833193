import { useEffect, useState } from "react";

/* triggers an update if the script becomes loaded */
/* checks every interval seconds */

export const useWindowProperty = (path: keyof Window, interval = 10000) => {
  const [, setState] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      if (window[path] !== undefined) {
        setState(1);
        clearInterval(id);
      }
    }, interval);
  }, []);

  return window[path];
};
