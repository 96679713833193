import type { PropsWithChildren } from "react";
import React from "react";
import { ToastProvider as ReactToastProvider } from "react-toast-notifications";

export const ToastProvider = ({ children }: PropsWithChildren<object>) => (
  <ReactToastProvider
    autoDismiss
    autoDismissTimeout={__HERMES__ ? 3000 : 10000}
  >
    {children}
  </ReactToastProvider>
);
