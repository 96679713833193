import { useQuery } from "@apollo/client/react";

import { useEffect } from "react";
import type {
  CheckVersionAtlasQuery,
  CheckVersionQuery,
} from "../../types/store/customTypes";
import { useRouteLocation } from "../../utils/routes/reactRouter";
import { useCheckVersionHyperionQuery } from "../../api.generated";
import { checkVersionAtlasQuery, checkVersionQuery } from "./queries";

export const useAutoForceRefreshAtlas = () => {
  const location = useRouteLocation();

  const query = useQuery<CheckVersionAtlasQuery>(checkVersionAtlasQuery, {
    fetchPolicy: "network-only",

    onCompleted: (data) => {
      //WARNING: do not use optional chaining here ! (not sure why)
      if (data && data.checkVersionAtlas.number > __VERSION_NUMBER__ATLAS__) {
        window.location.reload();
      }
    },
    pollInterval: 3600000,
    skip: __MOCKED_GRAPHQL__ || __IS_DEV__,
  });

  useEffect(() => {
    void query.refetch();
  }, [location.pathname]);
};

export const useAutoForceRefreshHermes = () => {
  const location = useRouteLocation();
  const query = useQuery<CheckVersionQuery>(checkVersionQuery, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      //WARNING: do not use optional chaining here ! (not sure why)
      if (data && data.checkVersion.number > __VERSION_NUMBER__) {
        window.location.reload();
      }
    },
    pollInterval: 3600000,
    skip: __MOCKED_GRAPHQL__ || __IS_DEV__ || !__HERMES__,
  });

  useEffect(() => {
    void query.refetch();
  }, [location.pathname]);
};

export const useAutoForceRefreshHyperion = () => {
  const location = useRouteLocation();
  const query = useCheckVersionHyperionQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      //WARNING: do not use optional chaining here ! (not sure why)
      if (data && data.checkVersionHyperion.number > __VERSION_NUMBER__) {
        window.location.reload();
      }
    },
    pollInterval: 3600000,
    skip: __MOCKED_GRAPHQL__ || __IS_DEV__ || !__HERMES__,
  });

  useEffect(() => {
    void query.refetch();
  }, [location.pathname]);
};
