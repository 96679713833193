import "@ovrsea/ovrlib/theme/css/app/index-vite.css";

import App from "@ovrsea/ovrlib/components/App";
import { preventBackspaceNavigation } from "@ovrsea/ovrlib/utils/events/preventBackspaceNavigation";
import TreeShakingTest from "@ovrsea/ovrlib/components/Error/TreeShakingTest";
import AtlasApolloProvider from "@ovrsea/ovrlib/components/Apollo/AtlasApolloProvider";
import { ReactAuth0Provider } from "@ovrsea/ovrlib/components/Auth/ReactAuth0Provider";
import { StatusContainer } from "@ovrsea/ovrlib/components/Status/StatusContainer";

import { renderApp } from "./renderApp";

//DO NOT TOUCH THIS LINE (TreeShakingTest)
if (__IS_DEV__) {
  TreeShakingTest();
} // TestKey: used in a false statement: not in the bundle

__IS_DEV__ && TreeShakingTest(); // TestKey: normal export: in the bundle

document.addEventListener("keydown", preventBackspaceNavigation);

void renderApp(App, {
  ApolloProvider: AtlasApolloProvider,
  AuthenticationProvider: ReactAuth0Provider,
  StatusContainer,
});
