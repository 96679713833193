import React, { useCallback } from "react";
import { createContainer, useContainer } from "unstated-next";

import { useQueryStringTo } from "../useQueryStringModal";
import { LoadableCancelOrderModalContainer } from "../../components/Order/Modals/CancelOrderModalContainer.loadable";
import { useSession } from "../../components/App/SessionProvider";
import withErrorBoundary from "../../components/Layout/HOCs/withErrorBoundary";

type Props = {
  foreseaName: string;
};

const useCancelOrderModalContext = () => {
  const [cancelOrderModalParams, goTo] = useQueryStringTo<{
    action: string;
    foreseaName: string;
  }>();

  const openCancelOrderModal = useCallback(
    ({ foreseaName }: Props) => {
      goTo({ action: "cancelOrder", foreseaName });
    },
    [goTo],
  );
  const closeCancelOrderModal = useCallback(() => goTo({}), [goTo]);

  const isCancelOrderModalOpened =
    (cancelOrderModalParams && !!cancelOrderModalParams.foreseaName) || false;

  const { foreseaName } = cancelOrderModalParams;

  return {
    closeCancelOrderModal,
    foreseaName,
    isCancelOrderModalOpened,
    openCancelOrderModal,
  };
};

const CancelOrderModalCtx = createContainer(useCancelOrderModalContext);

export const useCancelOrderModalContainer = () =>
  useContainer(CancelOrderModalCtx);

export const CancelOrderModalProvider = withErrorBoundary(
  ({ children }: { children: React.ReactNode }) => {
    const { session } = useSession();

    if (window.PRERENDERING?.active || !session) {
      return <>{children}</>;
    }

    return (
      <CancelOrderModalCtx.Provider>
        {children}
        <LoadableCancelOrderModalContainer />
      </CancelOrderModalCtx.Provider>
    );
  },
);
