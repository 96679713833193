import BasicLoadable from "../../Loadable/BasicLoadable";

export const LoadableViewerModal = BasicLoadable({
  loader: () => import("./ViewerModalContainer"),
  loading: null,
});

export const LoadableDropboxViewerModal = BasicLoadable({
  loader: () => import("./DropboxViewerModalContainer"),
  loading: null,
});
