import { useForceRefreshAtlas } from "@ovrsea/ovrlib/graphql/versioning/useForceRefreshAtlas";
import React, { memo } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { AppRouteTitle } from "@ovrsea/ovrlib/components/App/AppTitle";
import { AtlasAppHelmet } from "@ovrsea/ovrlib/components/Helmet/AtlasAppHelmet";
import { useAutoForceRefreshAtlas } from "@ovrsea/ovrlib/graphql/versioning/versioning";
import { useTracker } from "@ovrsea/ovrlib/graphql/auth/auth";
import { useSession } from "@ovrsea/ovrlib/components/App/SessionProvider";
import FrontConfig from "@ovrsea/ovrlib/utils/config";
import { LoadablePreloader } from "./components/Tools/Preloader.loadable";
import { LoadableAtlasRoutes } from "./AtlasRoutes.loadable";
import { LoadableAtlasHotkeys } from "./components/Hotkeys/AtlasHotkeys.loadable";
import { LoadableCompleteIncidentInformationModal } from "./components/Incidents/CompleteIncidentInformation.loadable";

FrontConfig.config({
  supportEmail: "antoine@ovrsea.com",
});

if (__ENABLE_TRACKERS__) {
  datadogRum.init({
    allowedTracingUrls: [__SERVERLESS_API_URL__],
    applicationId: "9451d810-460c-4f42-aa1c-2a78d5c17937",
    clientToken: "pub234a4cb5643b041e28bda077f562dd0d",
    defaultPrivacyLevel: "allow",
    env: __IS_DEV__ ? "dev" : "prod",
    service: "atlas",
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: "datadoghq.eu",
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: __BUILD_COMMIT__,
  });

  datadogRum.startSessionReplayRecording();
}

const Atlas = memo(() => {
  console.log("Rendering Atlas.");
  useAutoForceRefreshAtlas();
  useTracker();
  useSession();
  const { showForceRefreshBanner } = useForceRefreshAtlas();

  return (
    <>
      <LoadableAtlasHotkeys />
      <LoadablePreloader />
      <AtlasAppHelmet />
      <AppRouteTitle />
      <LoadableAtlasRoutes showForceRefreshBanner={showForceRefreshBanner} />
      <LoadableCompleteIncidentInformationModal />
    </>
  );
});

Atlas.displayName = "Atlas";

export default Atlas;
