import type { PropsWithChildren } from "react";
import React, { useEffect } from "react";
import { useReactRouter } from "../../utils/routes/reactRouter";

const useScrollRestoration = () => {
  const { pathname } = useReactRouter().location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const ScrollRestoration = ({ children }: PropsWithChildren<object>) => {
  useScrollRestoration();

  return <>{children}</>;
};
