import { flatten } from "lodash-es";
import type algoliasearch from "algoliasearch";
import { useCallback, useState } from "react";
import type { Hit } from "react-instantsearch-core";
import { compact } from "lodash-es";
import pDebounce from "p-debounce";
import { logError } from "../../libs/errors/logError";
import { useLatestQueryResult } from "../useLatestQueryResult";
import type { HitResponse } from "../../components/Algolia/types";
import type { OvrseaSearchClient } from "../../components/Algolia/SearchClientProvider";
import { useSearchClient } from "../../components/Algolia/SearchClientProvider";

export type Indice = {
  index: string;
  // @ts-expect-error
  params: algoliasearch.QueryParameters;
};

export type UseAlgoliaSearchParams = {
  algoliaSearchKey: string;
  applicationId: string;
  indices: Indice[];
};

type SearchOnAlgoliaParams = {
  algoliaSearchKey: string;
  applicationId: string;
  indices: Indice[];
  searchBarCurrentValue?: string;
  searchClient: OvrseaSearchClient;
};

export const searchOnAlgolia = pDebounce(
  async ({
    algoliaSearchKey,
    applicationId,
    indices,
    searchBarCurrentValue,
    searchClient,
  }: SearchOnAlgoliaParams) => {
    if (searchBarCurrentValue && searchBarCurrentValue.length > 1) {
      const client = searchClient(applicationId, algoliaSearchKey);

      const queries = indices.map((index) => ({
        indexName: index.index,
        params: {
          getRankingInfo: true,
          highlightPostTag: "*",
          highlightPreTag: "*",
          ...index.params,
        },
        query: searchBarCurrentValue,
      }));

      const algoliaResult = await client.search<any>(queries);

      return compact(
        flatten(
          algoliaResult.results.map(
            (indexResults) => "hits" in indexResults && indexResults.hits,
          ),
        ),
      );
    }

    return [];
  },
  200,
);

export const useAlgoliaQuery = ({
  algoliaSearchKey,
  applicationId,
  indices,
  searchBarCurrentValue,
}: { searchBarCurrentValue: string | undefined } & UseAlgoliaSearchParams) => {
  const searchClient = useSearchClient();
  const [error, setError] = useState<Error | null>(null);

  const onError = (err: Error) => {
    void logError(
      //@ts-expect-error
      `An error occurred querying Algolia with status ${err.status}.\n${err.message}`,
      indices,
      "algolia_query",
    );
    setError(err);
  };

  const uniqSearchOnAlgolia = useCallback(() => {
    setError(null);

    return searchOnAlgolia({
      algoliaSearchKey,
      applicationId,
      indices,
      searchBarCurrentValue,
      searchClient,
    });
  }, [searchBarCurrentValue]);

  const { loading, queryResult } =
    useLatestQueryResult(uniqSearchOnAlgolia, onError) ?? [];

  return {
    algoliaHits: (queryResult ?? []) as Hit<HitResponse>[],
    error,
    isLoading: loading,
    searchBarCurrentValue,
  };
};
