import { cacheSizes } from "@apollo/client/utilities";
import { InMemoryCache } from "@apollo/client/cache/inmemory/inMemoryCache";
import introspectionResult from "../../fragmentTypes.json" assert { type: "json" };

// Cache is preserved between new ApolloClient invokations
const appCache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  resultCaching: true,
  //dans un monde ideal, reccuperer tous les typenames et mettre merge: true pour tout le monde
  typePolicies: {
    AgentScope: {
      merge: true,
    },
    ClientCommercialRelationshipInformation: {
      merge: true,
    },
    ClientInsuranceSpecificities: {
      merge: true,
    },
    Connections: {
      merge: true,
    },
    EventDate: {
      merge: true,
    },
    ExchangeRate: {
      merge: true,
    },
    Harbor: {
      merge: true,
    },
    Routing: {
      merge: true,
    },
    SharedWith: {
      merge: true,
    },
  },
});

cacheSizes["inMemoryCache.executeSelectionSet"] = 2000000;
cacheSizes["inMemoryCache.executeSubSelectedArray"] = 2000000;

void appCache.reset();

export { appCache };
