const CHARACTERS_TO_ESCAPE = {
  HASHTAG: {
    ESCAPED: { REGEX: /%23/g, STRING: "%23" },
    ORIGINAL: { REGEX: /#/g, STRING: "#" },
  },
  SLASH: {
    ESCAPED: { REGEX: /%2F/g, STRING: "%2F" },
    ORIGINAL: { REGEX: /\//g, STRING: "/" },
  },
};

const { HASHTAG, SLASH } = CHARACTERS_TO_ESCAPE;

export const encodeSubRoute = (subRoute: string) =>
  subRoute
    .replace(SLASH.ORIGINAL.REGEX, SLASH.ESCAPED.STRING)
    .replace(HASHTAG.ORIGINAL.REGEX, HASHTAG.ESCAPED.STRING);

export const decodeSubRoute = (encodedSubRoute: string) =>
  encodedSubRoute
    .replace(SLASH.ESCAPED.REGEX, SLASH.ORIGINAL.STRING)
    .replace(HASHTAG.ESCAPED.REGEX, HASHTAG.ORIGINAL.STRING);

const generateProductPage = ({
  productOwnerClientId,
  sku,
}: {
  productOwnerClientId: string;
  sku: string;
}) => {
  const encodedSku = encodeSubRoute(sku);

  return `/products/show/${productOwnerClientId}/${encodedSku}`;
};

const generateQuotationPageSummary = (foreseaName: string) =>
  `/quotations/show/${foreseaName}/summary`;

const generateAllProductsPage = ({
  productOwnerClientId,
}: {
  productOwnerClientId: string;
}) => {
  if (__ATLAS__) {
    return `/clients/show/${productOwnerClientId}/products`;
  }

  return "/products/all";
};

const CUSTOMS_ROUTE_PREFIX = "customs";
const CUSTOMS_CLEARANCE_ROUTE_SUFFIX = `${CUSTOMS_ROUTE_PREFIX}/customs-clearance`;
const CUSTOMS_TARIFF_NUMBER_ROUTE_SUFFIX = `${CUSTOMS_ROUTE_PREFIX}/customs-tariff-number`;

const generateActiveCustomsClearancePage = ({
  clientId,
}: {
  clientId?: string;
}) => {
  if (__ATLAS__ && clientId) {
    return `/clients/show/${clientId}/${CUSTOMS_CLEARANCE_ROUTE_SUFFIX}`;
  }

  return `/${CUSTOMS_CLEARANCE_ROUTE_SUFFIX}`;
};

const generateAtlasClientPage = ({ clientId }: { clientId: string }) => {
  if (__ATLAS__) {
    return `/clients/show/${clientId}/contacts`;
  }

  return "/";
};

const generateAtlasTrafficPage = ({
  action,
  clientId,
  tab,
  trafficId,
}: {
  action?: "edit-settings";
  clientId: string;
  tab?: "dashboard" | "pricing" | "settings" | "shipments";
  trafficId: string;
}) => {
  const tabToGoTo = tab ?? "dashboard";

  const queryParams = action ? `?action=${action}` : "";

  return `/clients/show/${clientId}/traffics/show/${trafficId}/${tabToGoTo}${queryParams}`;
};

export const ROUTES = {
  ACTIVE_CUSTOMS_CLEARANCE_PAGE: generateActiveCustomsClearancePage,
  ALL_PRODUCT_PAGE: generateAllProductsPage,
  ATLAS_CLIENT_PAGE: generateAtlasClientPage,
  ATLAS_TRAFFIC_PAGE: generateAtlasTrafficPage,
  CUSTOMS_CLEARANCE_PAGE: ({
    ovrseaReference,
    tab,
  }: {
    ovrseaReference: string;
    tab?: string;
  }) =>
    tab
      ? `/${CUSTOMS_CLEARANCE_ROUTE_SUFFIX}/show/${ovrseaReference}/${tab}`
      : `/${CUSTOMS_CLEARANCE_ROUTE_SUFFIX}/show/${ovrseaReference}`,
  CUSTOMS_CLEARANCE_ROUTE_SUFFIX,
  CUSTOMS_CLEARANCES_DASHBOARD: `/${CUSTOMS_CLEARANCE_ROUTE_SUFFIX}/active`,
  CUSTOMS_ROUTE_PREFIX,
  CUSTOMS_TARIFF_NUMBER_PAGE: CUSTOMS_TARIFF_NUMBER_ROUTE_SUFFIX,
  PRODUCT_PAGE: generateProductPage,
  QUOTATION_PAGE: generateQuotationPageSummary,
  REQUEST_CUSTOMS_CLEARANCE_PAGE: `/${CUSTOMS_CLEARANCE_ROUTE_SUFFIX}/request`,
  REQUEST_PRICES_ON_TRAFFIC: "/traffics/request",
};
