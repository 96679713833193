type FrontConfigType = {
  supportEmail: string;
};

class FrontConfig {
  public supportEmail: string = "antoine@ovrsea.com";

  public constructor() {}

  public config(config: FrontConfigType) {
    Object.assign(this, config);
  }
}

export default new FrontConfig();
