import type { User } from "@auth0/auth0-react";
import { createContext, useContext } from "react";
import { noop } from "../../utils/noop";

export type AuthenticationInterface = {
  authToken?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: () => Promise<void>;
  logout: () => void;
  resetPassword?: (email: string) => void;
  user?: User;
};

export const AuthenticationContext = createContext<AuthenticationInterface>({
  isAuthenticated: false,
  isLoading: false,
  login: async () => noop(),
  logout: noop,
  user: undefined,
});

export const useAuthenticationContainer = () =>
  useContext(AuthenticationContext);
