import type { CSSProperties } from "react";
import React from "react";

import type { Sx } from "@ovrsea/vulcan";
import { Body, Card, Center, Flex, Spinner, VStack } from "@ovrsea/vulcan";

const cardSx: Sx = {
  alignItems: "center",
  backgroundColor: "transparent",
  justifyContent: "center",
  minHeight: "200px",
};
const gridStyle: CSSProperties = { height: "200px", margin: 0 };

export type LoadingProps = {
  basic?: boolean;
  error?: Error | null;
  sx?: Sx;
};

const Loading = ({ basic, error, sx }: LoadingProps) => {
  if (error) {
    return (
      <VStack alignItems="center" spacing={8} style={gridStyle}>
        <Body>We encountered an error</Body>
        {!__HERMES__ && (
          <Card
            sx={(theme) => ({ backgroundColor: theme.colors.neutral.light })}
          >
            <Body size="sm">
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </Body>
          </Card>
        )}
      </VStack>
    );
  }

  return (
    <Card as={Flex} borderColor={basic ? "none" : "neutral"} sx={[cardSx, sx]}>
      <Center>
        <Spinner isActive />
      </Center>
    </Card>
  );
};

export default Loading;
