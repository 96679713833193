export const indices = {
  CLIENTS: `${__ALGOLIA_INDEX_PREFIX__}_clients`,
  CONTACTS: `${__ALGOLIA_INDEX_PREFIX__}_contacts`,
  CUSTOMS_CLEARANCE_REQUESTS: `${__ALGOLIA_INDEX_PREFIX__}_customs_clearance_requests`,
  LOCATIONS: `${__ALGOLIA_INDEX_PREFIX__}_locations`,
  ORDER_PREPARATION: `${__ALGOLIA_INDEX_PREFIX__}_order_preparation`,
  ORDERS: `${__ALGOLIA_INDEX_PREFIX__}_orders`,
  PURCHASE_ORDERS: `${__ALGOLIA_INDEX_PREFIX__}_purchaseOrders`,
  SHIPOWNERS: `${__ALGOLIA_INDEX_PREFIX__}_shipowners`,
};
