import * as React from "react";
import { useEffect } from "react";
import { AUTH0_USER_NOT_REGISTERED_CODE } from "@ovrsea/ovrerrors";
import { useSession } from "../App/SessionProvider";
import LoadingPage from "../../pages/LoadingPage";
import InvalidLoginPage from "../../pages/InvalidLoginPage";
import { useAuthenticationContainer } from "./AuthenticationContext";

const isErrorAuth0NotAuthenticated = (error: unknown) =>
  //@ts-expect-error
  error?.networkError?.result?.code === AUTH0_USER_NOT_REGISTERED_CODE;

export const AuthenticationGate = ({
  children,
  loadingPageElement = <LoadingPage />,
}: {
  children: React.ReactNode;
  loadingPageElement?: React.ReactNode;
}) => {
  const { isAuthenticated, isLoading, login, logout } =
    useAuthenticationContainer();
  const { error, session } = useSession();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      void login();
    }

    /**
     * Prevent user logging in with wrong
     * google account (aka non ovrsea account)
     * from being stuck on loader pae
     */
    if (isAuthenticated && error && !isErrorAuth0NotAuthenticated(error)) {
      (async () => {
        await logout();
      })();
    }
  }, [isAuthenticated, isLoading, session, error]);

  if (isAuthenticated && session) {
    return <>{children}</>;
  }

  if (isErrorAuth0NotAuthenticated(error)) {
    return <InvalidLoginPage />;
  }

  return <>{loadingPageElement}</>;
};

export const BasicAuthenticationGate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuthenticated } = useAuthenticationContainer();
  const { session } = useSession();

  if (isAuthenticated && session) {
    return <>{children}</>;
  }

  return null;
};
