import React, { useMemo } from "react";

import { createContainer, useContainer } from "unstated-next";

import { useQueryStringTo } from "../useQueryStringModal";
import { LoadableLocationModals } from "../../components/Network/Locations/Modals/LocationModals.loadable";
import { BasicAuthenticationGate } from "../../components/Auth/AuthenticationGate";
import withErrorBoundary from "../../components/Layout/HOCs/withErrorBoundary";

export const useLocationModal = (extraAction = "") => {
  const [queryStringParams, goTo, urlTo] = useQueryStringTo<{
    [key: string]: string;
    action: "create-location" | "edit-location" | "show-location";
    initialClientId: string;
    locationId: string;
  }>();

  const openCreateLocationModalUrl = (extraActionValue?: string) =>
    urlTo({ action: "create-location", [extraAction]: extraActionValue });

  const openShowLocationModalUrl = (
    location: { id: string },
    extraActionValue?: string,
  ) =>
    urlTo({
      action: "show-location",
      [extraAction]: extraActionValue,
      locationId: location.id,
    });

  const openUpdateLocationModalUrl = (
    location: { id: string },
    extraActionValue?: string,
  ) =>
    urlTo({
      action: "edit-location",
      [extraAction]: extraActionValue,
      locationId: location.id,
    });

  const handleOpenCreateLocationModal = (
    initialClientId: string,
    extraActionValue?: string,
  ) =>
    goTo({
      action: "create-location",
      [extraAction]: extraActionValue,
      initialClientId, // initialClientId is initial because the client ID can be changed afterwards via a selectfield
    });

  const handleOpenShowLocationModal = (
    location: { id: string },
    extraActionValue?: string,
  ) =>
    goTo({
      action: "show-location",
      [extraAction]: extraActionValue,
      locationId: location.id,
    });

  const handleOpenUpdateLocationModal = (
    locationId: string,
    extraActionValue?: string,
  ) =>
    goTo({
      action: "edit-location",
      [extraAction]: extraActionValue,
      locationId,
    });

  const handleCloseLocationModal = () => goTo({});

  const createLocationModalOpened =
    queryStringParams.action === "create-location";
  const showLocationModalOpened =
    queryStringParams.action === "show-location" &&
    !!queryStringParams.locationId;
  const updateLocationModalOpened =
    queryStringParams.action === "edit-location" &&
    !!queryStringParams.locationId;

  const { initialClientId, locationId } = queryStringParams;
  const extraActionValue = extraAction && queryStringParams[extraAction];

  return useMemo(
    () => ({
      createLocationModalOpened,
      extraActionValue,
      handleCloseLocationModal,
      handleOpenCreateLocationModal,
      handleOpenShowLocationModal,
      handleOpenUpdateLocationModal,
      initialClientId,
      locationId,
      openCreateLocationModalUrl,
      openShowLocationModalUrl,
      openUpdateLocationModalUrl,
      showLocationModalOpened,
      updateLocationModalOpened,
    }),
    [queryStringParams, goTo, urlTo],
  );
};

const LocationModal = createContainer(useLocationModal);

export const useLocationModalContainer = () => useContainer(LocationModal);

export const LocationModalProvider = withErrorBoundary(
  ({ children }: { children: React.ReactNode }) => {
    if (window.PRERENDERING?.active) {
      return <>{children}</>;
    }

    return (
      <LocationModal.Provider>
        {children}
        <BasicAuthenticationGate>
          <LoadableLocationModals />
        </BasicAuthenticationGate>
      </LocationModal.Provider>
    );
  },
);
