import { fromPairs } from "lodash-es";
// eslint-disable-next-line no-restricted-imports
import type { Location, URLSearchParamsInit } from "react-router-dom";
// eslint-disable-next-line no-restricted-imports
import { useLocation, useParams, useSearchParams } from "react-router-dom";

type UseQueryStringReturn<QueryStringParams> = {
  currentQueryString?: string;
  currentQueryStringParams: Partial<QueryStringParams>;
  setSearchParams: (nextSearch: URLSearchParamsInit) => void;
};

type IterableSearch = Iterable<[string, string]>;

export const useQueryString = <
  QueryStringParams extends Record<string, string | undefined>,
>(): UseQueryStringReturn<QueryStringParams> => {
  const [search, setSearchParams] = useSearchParams();

  const urlSearch = new URLSearchParams(search) as unknown as IterableSearch;
  const searchParams = fromPairs([...urlSearch]) as Partial<QueryStringParams>;

  return {
    currentQueryString: urlSearch.toString(),
    currentQueryStringParams: searchParams,
    setSearchParams,
  };
};

export type ReactRouterInfo<
  T extends Record<string, string> = Record<string, string>,
> = {
  readonly location: Location;
  readonly match: {
    readonly params: T;
    readonly path: string;
  };
};

export const useRouteParams = <
  T extends Record<string, string> = Record<string, string>,
>() => useParams() as T;

export const useRouteLocation = useLocation;

export const useReactRouter = <
  T extends Record<string, string> = Record<string, string>,
>(): ReactRouterInfo<T> => {
  const location: Location = useLocation();
  const params = useRouteParams<T>();
  const path = location.pathname;

  return {
    location,
    match: {
      params,
      path,
    },
  };
};

export { RouterProvider } from "./RouterProvider";

// eslint-disable-next-line no-restricted-imports
export type { LinkProps, URLSearchParamsInit } from "react-router-dom";

// eslint-disable-next-line no-restricted-imports
export {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Router,
  Routes,
  useNavigate as useOvrseaNavigate,
} from "react-router-dom";

// eslint-disable-next-line no-restricted-imports
export type { NavigateFunction, NavigateOptions } from "react-router-dom";

export { StaticRouter } from "react-router-dom/server";
