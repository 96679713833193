import { identity, isEqual, pickBy } from "lodash-es";
import { useCallback, useMemo } from "react";
import {
  useOvrseaNavigate,
  useQueryString,
  useReactRouter,
} from "../utils/routes/reactRouter";

export type QueryStringParams = Record<string, string | undefined>;

export const objectToSearchParams = <T extends QueryStringParams>(
  params: T,
) => {
  const compactParams = pickBy(params, identity) as Record<string, string>;

  return new URLSearchParams(compactParams);
};

const appendQueryStringToUrl =
  (baseUrl = "") =>
  <T extends QueryStringParams = QueryStringParams>(params: T) => {
    const search = objectToSearchParams(params).toString();

    return `${baseUrl}?${search}`;
  };

const useQueryStringTo = <
  T extends QueryStringParams = QueryStringParams,
>() => {
  const routerInfos = useReactRouter();
  const baseUrl = routerInfos.location.pathname;
  const { currentQueryString, currentQueryStringParams } =
    useQueryString<Partial<T>>();
  const navigate = useOvrseaNavigate();

  const urlTo = appendQueryStringToUrl(baseUrl);

  const goTo = (params: NonNullable<unknown> | T) => navigate(urlTo(params));

  return useMemo(
    () => [currentQueryStringParams, goTo, urlTo] as const,
    [currentQueryString, baseUrl],
  );
};

const useQueryStringModal = <T extends QueryStringParams = QueryStringParams>(
  queryStringParams: T,
) => {
  const [currentQueryStringParams, goTo, urlTo] = useQueryStringTo<T>();

  const isModalOpened = isEqual(currentQueryStringParams, queryStringParams);

  const openCloseModal = useCallback(() => {
    if (!isModalOpened) {
      goTo(queryStringParams);
    } else {
      goTo({});
    }
  }, [goTo, isModalOpened, queryStringParams]);

  const url = useMemo(
    () => urlTo(queryStringParams),
    [urlTo, queryStringParams],
  );

  // cannot be extended very well to multiple keys...

  return [isModalOpened, openCloseModal, url] as const;
};

export { appendQueryStringToUrl, useQueryStringModal, useQueryStringTo };
