import type { PropsWithChildren } from "react";
import React, { useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { BrowserRouter, useNavigate } from "react-router-dom";

type Props = object;

const useInjectCypressNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (window as any).cyNavigate = navigate;
  }, [navigate]);

  return null;
};

const CypressNavigation = () => {
  useInjectCypressNavigation();

  return null;
};

export const RouterProvider = ({
  children,
  ...others
}: PropsWithChildren<Props>) => (
  <BrowserRouter {...others}>
    <CypressNavigation />
    {children}
  </BrowserRouter>
);
