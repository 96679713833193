import {
  DEFAULT_LOCALE,
  SPANISH_LOCALES,
  type SupportedLocales,
} from "@ovrsea/i18n";
import { create } from "zustand";
import { first } from "lodash-es";
import { FRENCH_LOCALES } from "@ovrsea/i18n";
import { ITALIAN_LOCALES } from "@ovrsea/i18n/src/constants";

export const getBrowserLocale = () => {
  const navigatorLanguage =
    // @ts-expect-error
    globalThis?.navigator?.language || globalThis?.navigator?.userLanguage;
  const firstNavigatorLanguage = first(globalThis?.navigator?.languages);

  const language = firstNavigatorLanguage || navigatorLanguage;

  if (FRENCH_LOCALES.includes(language)) {
    return "fr";
  }

  if (SPANISH_LOCALES.includes(language)) {
    return "es";
  }
  if (ITALIAN_LOCALES.includes(language)) {
    return "it";
  }

  return DEFAULT_LOCALE;
};

const browserLocale = getBrowserLocale();

const useLocaleState = create<{
  locale: SupportedLocales;
  setLocale: (locale: SupportedLocales) => void;
}>((set) => ({
  locale: browserLocale,
  setLocale: (locale: SupportedLocales) => set({ locale }),
}));

const useLocale = () => useLocaleState((state) => state.locale);

export { useLocale, useLocaleState };
