import type { PropsWithChildren } from "react";
import React, { createContext, useContext } from "react";
import type { SearchClient } from "algoliasearch";
import algoliasearch from "algoliasearch";

export type OvrseaSearchClient = (
  applicationId: string,
  token: string,
) => {
  initIndex: SearchClient["initIndex"];
  search: SearchClient["search"];
};

const SearchClientContext = createContext<OvrseaSearchClient>(algoliasearch);

export type SearchClientProviderProps = {
  searchClient?: OvrseaSearchClient;
};

export const SearchClientProvider: React.FC<
  PropsWithChildren<SearchClientProviderProps>
> = ({ children, searchClient = algoliasearch }) => {
  return (
    <SearchClientContext.Provider value={searchClient}>
      {children}
    </SearchClientContext.Provider>
  );
};

export const useSearchClient = (): OvrseaSearchClient => {
  const context = useContext(SearchClientContext);

  return context;
};
