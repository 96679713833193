import { gql } from "@apollo/client/core";

gql`
  query checkVersionHyperion {
    checkVersionHyperion {
      minimumVersionOrForceRefresh
      number
    }
  }
`;

export const checkVersionQuery = gql`
  query checkVersion {
    checkVersion {
      minimumVersionOrForceRefresh
      number
    }
  }
`;

export const checkVersionAtlasQuery = gql`
  query checkVersionAtlas {
    checkVersionAtlas {
      minimumVersionOrForceRefresh
      number
    }
  }
`;
