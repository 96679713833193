export const DEFAULT_LOCALE = "en" as const;

export const ENGLISH_LOCALES = [
  "en-US",
  "en-EG",
  "en-AU",
  "en-GB",
  "en-CA",
  "en-NZ",
  "en-IE",
  "en-ZA",
  "en-JM",
  "en-BZ",
  "en-TT",
  "en",
];

export const FRENCH_LOCALES = [
  "fr-FR",
  "fr-BE",
  "fr-CA",
  "fr-LU",
  "fr-MC",
  "fr-CH",
  "fr",
];

export const SPANISH_LOCALES = [
  "es-419",
  "es-AR",
  "es-BO",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-DO",
  "es-EC",
  "es-ES",
  "es-GT",
  "es-HN",
  "es-MX",
  "es-NI",
  "es-PA",
  "es-PE",
  "es-PR",
  "es-PY",
  "es-SV",
  "es-US",
  "es-UY",
  "es-VE",
  "es",
];

export const ITALIAN_LOCALES = ["it-IT", "it"];

export const SUPPORTED_LOCALES = ["fr", "en", "es", "it"] as const;

export type SupportedLocales = (typeof SUPPORTED_LOCALES)[number];
