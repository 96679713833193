import { OVRSEA_LOGISTIC_AGENT_ID } from "@ovrsea/ovrutils";
import {
  isEmployee,
  isLogisticAgentUser,
  isUser,
} from "../../../utils/typescript/isUserOrEmployee";
import { useSession } from "../../App/SessionProvider";
import type { Language } from "../../../types/store/customTypes";

export const useAccountInfo = () => {
  const { session } = useSession();

  return session?.person;
};

export const useUserForFeatureFlag = () => {
  const { session } = useSession();

  //This is not to throw if we have a microservice without session like pdf for instance
  if (!session) {
    return null;
  }

  return session.person;
};

export const useUser = () => {
  const { session } = useSession();

  if (!session) {
    throw new Error("User not defined in the context.");
  }

  return session.person;
};

export const useCurrentUserCompany = () => {
  const { session } = useSession();

  if (!session) {
    throw new Error("User not defined in the context.");
  }

  if (isUser(session.person)) {
    return {
      id: session.person.client.id,
      type: "client",
    };
  }
  if (isEmployee(session.person)) {
    return {
      id: OVRSEA_LOGISTIC_AGENT_ID,
      type: "logistic_agent",
    };
  }
  if (isLogisticAgentUser(session.person)) {
    return {
      id: session.person.logisticAgent.id,
      type: "logistic_agent",
    };
  }
  throw new Error("User not defined in the context.");
};

export const useUserWithRefetch = () => {
  const { refetch, session } = useSession();

  if (!session || session.person.__typename === "Employee") {
    throw new Error("User not defined in the context.");
  }

  return [session.person, refetch] as [
    {
      __typename: "User";
      email: string;
      firstName: null | string;
      id: string;
      language: Language | null;
      lastName: null | string;
      name: string;
      phone: null | string;
      pictureUrl: null | string;
    },
    () => void,
  ];
};

export const useUserClient = () => {
  const user = useUser();

  if (!isUser(user)) {
    return null;
  }

  return user.client;
};

export const useCurrentUserId = () => {
  const user = useUser();

  if (!isUser(user)) {
    return null;
  }

  return user.id;
};

export const useCurrentEmployeeId = () => {
  const user = useUser();

  if (!isEmployee(user)) {
    return null;
  }

  return user.id;
};

export const useCurrentLogisticAgentUserId = () => {
  const user = useUser();

  if (!isLogisticAgentUser(user)) {
    return null;
  }

  return user.id;
};
