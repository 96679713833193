import type { StaticFeatureFlag } from "@ovrsea/ovrlib/components/App/FeatureFlagProvider";
import { defaultStaticFeatureFlag } from "@ovrsea/ovrlib/components/App/FeatureFlagProvider";

export const ATLAS_FEATURE_FLAGS: StaticFeatureFlag = {
  ...defaultStaticFeatureFlag,
  canAlwaysUpdateCargo: true,
  canEditTracking: true,
  displayAllDocumentTypes: true,
  isAllowedToActivateTracking: true,
};
